<style>
.tr {
  display: table-row;
}
.td {
  display: table-cell;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import btnComponent from "@/components/btnComponent";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "โฟลว์สล็อต(ช่วงเวลา)",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    btnComponent,
    
  },
  data() {
    return {
      title: "โฟลว์สล็อต(ช่วงเวลา)",
      items: [
        {
          text: "newFlowSlot",
        },
        {
          text: "ช่องทางการรับรถ",
          active: true,
        },
      ],

      // update status
      optionsStatusFslot: [
        {
          repairStatusID: "COUT",
          nameTh: "Clock OUT",
        },
        {
          repairStatusID: "SW",
          nameTh: "เริ่มล้าง",
        },
        {
          repairStatusID: "FW",
          nameTh: "ล้างเสร็จ",
        },
      ],
      linkPtintFlowslot:"",
      repairStatus: [],
      itemVMP: "",
      svIdP: "",
      repairIdP: "",
      // end update status

      rowsService: [],
      loading: undefined,
      form: {},
      slotIdPut: "",
      branchId: "",
      branchIdLC: "",
      DateSearch: "",
      rowSlot: [],
      rowSlottest: "",
      rowSlot2: [],
      SlotNum: [],
      countRowSlot: "",
      localDataBranch: [], //เป็น-array Id
      localData: [],
      colSp: 4,
      fslot1: false,
      Col1: "",
      colAll: 4,
      listSlot: [],
      fulllistSlot: [],
      TimeIndex :[
        '08:00:00',
        '08:15:00',
        '08:30:00',
        '08:45:00',

        '09:00:00',
        '09:15:00',
        '09:30:00',
        '09:45:00',

        '10:00:00',
        '10:15:00',
        '10:30:00',
        '10:45:00',
        
        '11:00:00',
        '11:15:00',
        '11:30:00',
        '11:45:00',

        '12:00:00',
        '12:15:00',
        '12:30:00',
        '12:45:00',

        '13:00:00',
        '13:15:00',
        '13:30:00',
        '13:45:00',

        '14:00:00',
        '14:15:00',
        '14:30:00',
        '14:45:00',

        '15:00:00',
        '15:15:00',
        '15:30:00',
        '15:45:00',

        '16:00:00',
        '16:15:00',
        '16:30:00',
        '16:45:00',

      ],
      
      // num: 1,

      // end

      //Modal Add RO
      Sv: "",
      rowRo: [],
      Ro: [],
      roDate: "",
      rowUserTM: [],
      priMechanicId: "",
      loadingU: false,
      testcol: "",
      //ends modal RO
    };
  },
  computed: {
    // dataSlot: function(rowSlot){
    //   return this.rowSlot.filter(function(pslotId){
    //     return rowSlot.pslotId === pslotId;
    //   })
    // }
  },

  created() {
    this.getLocalData();
    this.getNow();
  },

  methods: {
    stylecol(strat , end){
      if(strat != null && end != null){
        const TimeCal =  moment
          .utc(
            moment(end, "HH:mm").diff(
              moment(strat, "HH:mm")
            )
          )
          .unix()/36
          // console.log('Current Time = ' , moment().format("HH:mm"));
          // console.log('PX = ' , TimeCal);
          this.apTop += 110;
        return 'width:'+TimeCal+'px; position: absolute;top: '+this.apTop+'px; background-color:green; color:white ';
      }else if(strat != '' && end == null){
        const TimeCal =  moment
          .utc(
            moment(moment(), "HH:mm").diff(
              moment(strat, "HH:mm")
            )
          )
          .unix()/36
          
          // console.log('PX = ' , TimeCal);
          
          this.apTop += 100;
        return 'width:'+TimeCal+'px; position: absolute;top: '+this.apTop+'px; background-color:green; color:white';
      }

    },
    
    customLabelSv({ cusName , licensePlate }) {
      return  `[${licensePlate}] — ${cusName}`
    },
    customLabel({ name, familyName }) {
      return `${name}  ${familyName}`;
    },
    postRepair() {
      useNetw
        .post(
          "api/service/repair/store",

          {
            svId: this.Sv.svId,
            roId: this.Ro,
            priMechanicId: JSON.stringify(this.priMechanicId.userId),
            pslotId: this.pslotId,
            fslotId: this.fslotId,
          }
        )
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs.modalPostVm.hide();
          this.getDataFlowSlot();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;

          //   this.getRoWage();
          // this.getRoPart();
          // this.$router.push({ name: "branch-wages" });
        });
    },
    getUser: function() {
      // this.overlayFlag = true;

      this.loadingU = true;
      useNetw
        .get("api/user", {
          params: {
            page: 1,
            perPage: 100,
            branchId: [this.branchIdLC],
          },
        })
        .then((response) => {
          this.rowUserTM = response.data.data;
          // this.perPage = response.data.perPage;
          // this.from = response.data.from;
          // this.totalRecord = response.data.total;
          // this.to = response.data.to;
          // console.log("userrrrrrrrrrr", response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loadingU = false; //skeleton false
        });
    },

    getDataRO() {
      // console.log(this.Sv.svId);
      useNetw
        .get("api/service/ro", {
          params: {
            page: 1,
            perPage: 100,
            svId: this.Sv.svId,
          },
        })
        .then((response) => {
          this.Ro = [];
          this.rowRo = response.data.data;
          // console.log("RO ===>", this.rowRo);

          // console.log("77777", response);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    getDataService: function() {
      this.isLoading = true;
      useNetw
        .get("api/service", {
          params: {
            page: 1,
            perPage: 100,
            // vin: this.vin.vin,
            branchId: [this.branchIdLC],
          },
        })
        .then((response) => {
          this.rowsService = response.data.data;
          // console.log(this.rowsService);

          // this.rowsSearch = {
          //   vin: response.data.data.vin,
          //   lisencePlate: response.data.data.lisencePlate,
          // };
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    popUpmodalPostVm( startTime, itemSlotNum) {
      // this.fslotId = fslotId;
      if(startTime == '08:00:00' || startTime == '08:15:00' || startTime == '08:30:00' || startTime == '08:45:00' ){
        this.fslotId = 1
      }
      else if(startTime == '09:00:00' || startTime == '09:15:00' || startTime == '09:30:00' || startTime == '09:45:00' ){
        this.fslotId = 1
      }
      else if(startTime == '10:00:00' || startTime == '10:15:00' || startTime == '10:30:00' || startTime == '10:45:00' ){
        this.fslotId = 2
      }
      else if(startTime == '11:00:00' || startTime == '11:15:00' || startTime == '11:30:00' || startTime == '11:45:00' ){
        this.fslotId = 2
      }
      else if(startTime == '13:00:00' || startTime == '13:15:00' || startTime == '13:30:00' || startTime == '13:45:00' ){
        this.fslotId = 3
      }
      else if(startTime == '14:00:00' || startTime == '14:15:00' || startTime == '14:30:00' || startTime == '14:45:00' ){
        this.fslotId = 3
      }
      else if(startTime == '15:00:00' || startTime == '15:15:00' || startTime == '15:30:00' || startTime == '15:45:00' ){
        this.fslotId = 4
      }
      else if(startTime == '16:00:00' || startTime == '16:15:00' || startTime == '16:30:00' || startTime == '17:45:00' ){
        this.fslotId = 4
      }else{
        this.fslotId = 2;
      }

      this.startTime = startTime;
      this.pslotId = itemSlotNum;
      this.roDate = this.DateSearch;
      // console.log("fslotId", this.fslotId);
      // console.log("startTime", this.startTime);
      // console.log("pslotP", this.pslotId);
      // console.log("dateP", this.roDate);
      this.$refs["modalPostVm"].show();
    },

    changeStatusFL(statusVm) {
      if (statusVm == "SW") {
        return "กำลังล้าง";
      } else if (statusVm == "CIN") {
        return "เข้าจอด";
      } else if (statusVm == "COUT") {
        return "เสร็จสิ้น";
      } else if (statusVm == "FW") {
        return "ล้างเสร็จ";
      }
    },

    colorChangeClass(itemVM) {
      return { 
        "bg-danger text-center text-white rounded border": itemVM == "CIN",
        "bg-primary text-center text-white rounded border": itemVM == "SW",
        "bg-success text-center text-white rounded border": itemVM == "FW",
        "bg-secondary text-center text-white rounded border": itemVM == "COUT",
      };
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchIdLC = user.branchId;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
      // console.log(this.localDataBranch);
    },

    fetchCarslot() {
      this.rowSlot.vehicle(function(value) {
        this.listSlot.push(value);
      });

      // console.log("vehicle", this.listSlot);
    },
    setCar(time) {
      this.listSlot.forEach((item) => {
        if (item.startTime == time) {
          this.dataX.push(item);
        }
      });

      return this.dataX;
      // console.log('listSlot ==',this.listSlot);
    },

    getDataFlowSlot: function() {
      this.loading = true;
      this.listSlot = [];
      useNetw
        .get("api/flow-slot", {
          params: {
            branchId: this.branchId.branchId,
            date: this.DateSearch,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowSlot = response.data.data;

          this.getDatParking();
          this.getDataService();

          // console.log("rowSlot ====== > ", this.rowSlot);

          this.rowSlot.forEach((item) => {
            item.vehicles.forEach((itemx) => {
              this.listSlot.push(itemx);
            });
          });
          // console.log("listSlot : ", this.listSlot);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา", err),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            // console.log("Fail");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDatParking: function() {
      this.loading = true;
      useNetw
        .get("api/master/gs-parking-slots", {
          params: {
            branchId: this.branchId.branchId,
            date: this.DateSearch,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.SlotNum = response.data.data;
          // console.log("SlotNum ====== > ", this.SlotNum);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getNow: function() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();

      this.DateSearch = date;
      
      // console.log(this.DateSearch);
    },
    popupModalUpdateFslot(itemVM) {
      this.itemVMP = itemVM;
      this.svIdP = itemVM.svId;
      this.repairIdP = itemVM.repairId;
      this.$refs["modalUpdateFslot"].show();

      // console.log("this.itemVMP", this.itemVMP);
      // console.log("this.svIdP", this.svIdP);
      // console.log("this.repairIdP", this.repairIdP);
    },
    closemodalUpdateFslot() {
      this.$refs["modalUpdateFslot"].hide();
      this.repairStatus = "";
    },
    putStatusVm: function() {
      this.loading = true;
      useNetw
        .put("api/service/repair/update", {
          svId: this.svIdP,
          repairId: this.repairIdP,
          status: this.repairStatus.repairStatusID,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.closemodalUpdateFslot();
          this.getDataFlowSlot();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.validationMessage),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    getReportFlowSlot() {
      this.loading = true;
      useNetw
        .get("api/flow-slot/print-flow-slot", {
          params: {
            branchId: this.branchId.branchId,
            date: this.DateSearch,
          },
        }) // ?เอามาจากไหน
        .then((data) => {
          // console.log(data.data.url);
          this.linkPtintFlowslot  = data.data.url;
          // this.$refs["modalPrintflowSlot"].show();
          // window.open(data.data.url, '_blank');
          window.location = (data.data.url);
          
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("ไม่พบข้อมูลรถในวันที่ " + this.DateSearch),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton-table
                  :rows="5"
                  :columns="6"
                  :table-props="{ bordered: false, striped: true }"
                  animation="throb"
                ></b-skeleton-table>
              </template>
              <div class="row">
                <div class="col-md-10">
                  <h4 class="card-title">วางแผนงานซ่อม</h4>
                </div>
                <div class="col-md-2 text-end">
                  <router-link :to="{ name: 'home' }">
                    <a class="btn btn-secondary">กลับสู่หน้าหลัก</a>
                  </router-link>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-5">
                  <div class="mb-3 position-relative">
                    <code> * </code>
                    <label for="branchId">สาขา</label><br />
                    <multiselect
                      v-model="branchId"
                      :options="localData"
                      label="nameTh"
                    >
                    </multiselect>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="mb-3 position-relative">
                    <code> * </code>
                    <label for="birthDate">วันที่</label>

                    <date-picker
                      v-model="DateSearch"
                      :first-day-of-week="1"
                      format="YYYY-MM-DD"
                      value-type="format"
                      lang="en"
                    >
                    </date-picker>
                  </div>
                </div>

                <div class="col-md-4 text-end">
                  <label for="Search">&nbsp;</label><br />
                  <btnComponent @click="getDataFlowSlot()" changeStyle="search"></btnComponent> &nbsp;
                  <button class="btn btn-primary" @click="getReportFlowSlot()"><i class="uil-print"></i>
                    พิมพ์รายงาน
                  </button>
                </div>
              </div>

              <hr />
              
              <b>สถานะ </b>
              ( <b style="color: #f46a6a">สีแดง : เข้าจอด</b> , <b style="color: #5b73e8">สีน้ำเงิน : กำลังล้าง</b>, <b style="color: #34c38f">สีเขียว : ล้างเสร็จ</b>, <b style="color: #74788d">สีเทา : เสร็จสิ้น</b> )
              <br>
              <code>*** กดที่ช่องว่างช่วงเวลาเพื่อเพิ่่มรถ</code>
              <hr>


              <div class="table-responsive">
                <table class="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th class="text-center" rowspan="2" style="width:200px;">ช่องบริการ</th>
                      <th colspan="4" class="text-center" style="width:200px;">08.00</th>
                      <th colspan="4" class="text-center" style="width:200px;">09.00</th>
                      <th colspan="4" class="text-center" style="width:200px;">10.00</th>
                      <th colspan="4" class="text-center" style="width:200px;">11.00</th>
                      <th colspan="4" class="text-center" style="width:200px;">12.00</th>
                      <th colspan="4" class="text-center" style="width:200px;">13.00</th>
                      <th colspan="4" class="text-center" style="width:200px;">14.00</th>
                      <th colspan="4" class="text-center" style="width:200px;">15.00</th>
                      <th colspan="4" class="text-center" style="width:200px;">16.00</th>
                      <!-- <th colspan="4" class="text-center">17.00</th>
                      <th colspan="4" class="text-center">18.00</th> -->
                    </tr>
                    <tr style="height: 30px;">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(itemSlotNum, Pindex) in SlotNum" :key="Pindex" style="height: 200px;">
                      <th class="text-center" colspan="1">
                        {{ itemSlotNum.nameTh }}
                      </th>
                  
                      <td class="text-center colDetail" style="padding: 0px; height:100px; position: relative;" 
                      v-for="(itemtime, time_index) in TimeIndex" :key="time_index" 
                      @click="popUpmodalPostVm(itemtime ,itemSlotNum.pslotId )"
                      >
                        
                        <div  
                          style="width:100%;height:50%;"
                          @click="popupModalUpdateFslot(listSlotData)"
                          v-for="(listSlotData, index) in 
                          listSlot.filter((listSlot) =>listSlot.startTime === itemtime &&
                          listSlot.pslotId == itemSlotNum.pslotId)"
                          :key="`${listSlotData.fslotId}`+`${itemSlotNum.pslotId}`+index"         
                          >
                          
                          <div 
                          
                          v-bind:class="colorChangeClass(listSlotData.repairStatus)" 
                          :style="stylecol(listSlotData.startTime , listSlotData.endTime)" >
                          
                            <b @click="popupModalUpdateFslot(listSlotData)">
                              {{ listSlotData.licensePlate }}</b> <br> 
                                (ช่าง : {{ listSlotData.mechName }}) <br />
                                ชื่อลูกค้า : คุณ{{ listSlotData.customerNameTh }}
                                <br />
                                สถานะ :
                                {{ changeStatusFL(listSlotData.repairStatus) }}
                          </div>
                          

                        </div>
                        
                      </td> 

                    </tr>
                  </tbody>
                </table>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>


    <b-modal ref="modalUpdateFslot" hide-footer title="อัพเดทสถานะการซ่อม">
      <center>
        <h2>หมายเลขทะเบียน : {{ itemVMP.licensePlate }}</h2>
        <h2>
          สถานะ :
          <multiselect
            v-model="repairStatus"
            :options="optionsStatusFslot"
            label="nameTh"
          >
          </multiselect>
        </h2>
      </center>
      <hr />
      <button class="btn btn-success" @click="putStatusVm()">อัพเดท</button>
    </b-modal>

    <b-modal size="xl" ref="modalPostVm" hide-footer title="นำรถเข้าซ่อม">
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 position-relative">
            <code> * </code>
            <label for="branchId">ลูกค้า</label><br />
            <multiselect
              v-model="Sv"
              :options="rowsService"
              :custom-label="customLabelSv"
              label="cusName"
              @input="getDataRO()"
            >
            </multiselect>
          </div>
        </div>

        <div class="col-6 col-sm-3 col-md-3">
          <div class="mb-3 position-relative">
            <code>* </code><label for="validation">วันที่เข้ารับบริการ</label>
            <date-picker
              v-model="roDate"
              format="YYYY-MM-DD"
              value-type="format"
            ></date-picker>
          </div>
        </div>

        <div class="col-md-3 col-sm-3">
          <div class="mb-3 position-relative">
            <label for="priMechanicId">ช่างที่รับผิดชอบ</label>
            <multiselect
              id="priMechanicId"
              type="search"
              v-model="priMechanicId"
              :options="rowUserTM"
              :custom-label="customLabel"
              :loading="loadingU"
              :close-on-select="true"
              :internal-search="true"
              open-direction="bottom"
              @search-change="getUser"
              placeholder=""
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}&nbsp;</span
                  ><span class="option__small">{{
                    props.option.familyName
                  }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="row">
        <code> * </code><label for="branchId">Repait Order</label><br />
        <div
          class="col-md-3"
          v-for="(itemRO, index) in rowRo"
          :key="'RO' + index"
        >
          <input :value="itemRO.roId" type="checkbox" v-model="Ro" /> &nbsp;{{
            itemRO.roCode
          }}
        </div>
      </div>

      <hr />
      <div class="text-end">
        <button class="btn btn-success" @click="postRepair()">เพิ่ม</button>
      </div>
    </b-modal>

    <b-modal
          title="เอกสารรายงานรถ"
          hide-footer
          size="sm"
          centered
          ref="modalPrintflowSlot"
        >
          <div class="row">
            <div class="col">
              <label for="amount"
                ><code>*</code> วันที่ : {{ DateSearch }} <br> {{ branchId.nameTh }}
              </label>
              <hr />

            </div>
          </div>
          <hr />

          <div class="row">
            <div class="col-lg-12">
              <div class="text-end">
                <a class="btn btn-primary" :href="this.linkPtintFlowslot" >
                  Print
                </a>
              </div>
            </div>
          </div>
        </b-modal>

        
  </Layout>
</template>
